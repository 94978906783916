import React, { useState, useEffect } from "react";
import http from '../_api/http';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const GroupGallery = (prop) => {
    const PUBLIC_URL = process.env.REACT_APP_APIURL;

    return (
        <div className="col-12" >
            <div className="col-12 row">
                <a className="backArrowLink" href="#" style={{marginLeft:"15px"}}  onClick={prop.back} ><em className="fa fa-arrow-left backArrow" ></em></a>
                <label style={{
                    marginLeft: "35%",
                    color: "orange",
                    fontWeight: "bold",
                    padding: "2px",
                    fontSize:"20px"
                }}>{prop.imgTitle}</label>
            </div>
            <div className="col-12 row">
                <PhotoProvider>
                    {prop.images.map((img, i) => {
                        return (
                            <div className="col-lg-3 col-md-4" key={i}>
                                <div className="galleryPhotoImg">

                                    <PhotoView key={i} src={PUBLIC_URL + "uploadimage/" + img}>
                                        <img src={PUBLIC_URL + "uploadimage/" + img} alt="" />
                                    </PhotoView>
                                </div>
                            </div>
                        )
                    })
                    }
                </PhotoProvider>
            </div>
        </div>
    )
}

export default GroupGallery