import React, { useState, useEffect } from "react";
import http from '../_api/http';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import GroupGallery from "./GroupGallery";

const PhotoGallery = () => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  const [groupShow, setGroupShow] = useState(false);
  const [mainImgShow, setmainImgShow] = useState(true);
  const [imageList, setimageList] = useState([]);
  const[ImgTitle,setImgTitle]= useState("");
  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=17&section=Photo').then((res) => {
      if (res.data.status === 200) {
        setImage(res.data.image);
        setLoading(false);
      }
    });
  }, []);
  const loadGroupImage = (obj, imgList, imgTitle) => {
    setGroupShow(true);
    setmainImgShow(false);
    setimageList(imgList.split(','));
    setImgTitle(imgTitle);
  }
  const goBack = () => {
    setGroupShow(false);
    setmainImgShow(true);
    setimageList([]);
  }
  return (
    <section className="gallery-one">
      <div className="container">
        {mainImgShow &&
          <div className="row" >

            {loading
              ? <h4>View Photo Gallery...</h4>
              :
              <>
                <PhotoProvider>
                  {image.map((item, i) => {
                    const images = item.image.split(",");
                    const displayImg = item.displayImage == "" || item.displayImage == null ? images[0] : item.displayImage;

                    return (
                      <div className="col-lg-3 col-md-4" key={i}>
                        <div className="galleryPhotoImg">
                          {images.length < 2 ?
                            <PhotoView key={i} src={PUBLIC_URL + "uploadimage/" + displayImg}>
                              <img src={PUBLIC_URL + "uploadimage/" + displayImg} alt="" />
                            </PhotoView> :
                            <>
                              <img src={PUBLIC_URL + "uploadimage/" + displayImg} style={{cursor:"pointer"}} alt="" onClick={(e) => { loadGroupImage(e, item.image, item.image_title) }} />
                            </>
                          }

                          <div className="gallary-one__content">
                            <p className="gallary-one__gallary" title={item.image_title}>
                              {item.image_title}
                            </p>
                          </div>
                          <a className="gallery-one__popup img-popup">
                            <i className="kipso-icon-plus-symbol"></i>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </PhotoProvider>
              </>
            }
          </div>
        }
        <div className="col-12">
          {groupShow &&
            <GroupGallery show={groupShow} images={imageList} imgTitle={ImgTitle} back={goBack}></GroupGallery>
          }
        </div>
      </div>
    </section >
  );
};

export default PhotoGallery;