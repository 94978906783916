import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import http from '../_api/http'
import twitterLogo from "../assets/images/social_media/twitter.jpg";
import facebookLogo from "../assets/images/social_media/facebook.png";
import youtubeLogo from "../assets/images/social_media/youtube.jpeg";
import instaLogo from "../assets/images/social_media/instagram.jpeg";
// import './Topbar.css';
import NewsLetter from './NewsLetter';

const Topbar = () => {
//  const [show, setShow] = useState(false)
  
 // const handleClose = () => setShow(false)
  //const handleShow = () => setShow(true)
  // const [loading, setLoading] = useState(true);
  //const [pdf, setPdf] = useState([])
  const PUBLIC_URL = process.env.REACT_APP_APIURL
  const logoStyle={
    height: "22px",
    width: "22px",
    borderRadius: "5px"
  }
  return (
    <div className='topbar-one'>
      <div className='container'>
        <div className='topbar-one__left'>
          <div className='header__social' style={{"marginLeft":"0px"}}>
            <a href='https://twitter.com/suratsas'>
              <img style={logoStyle}  src={twitterLogo} alt="twitter"/>
            </a>
            <a href='https://www.facebook.com/profile.php?id=100093837762077&mibextid=ZbWKwL'>
            <img style={logoStyle}  src={facebookLogo} alt="facebook"/>
            </a>
            <a href='https://www.youtube.com/channel/UChJ3gkcAe2V8fwM2xjV4yYg'>
            <img style={logoStyle}  src={youtubeLogo} alt="youtube"/>
            </a>
            <a href='https://www.instagram.com/shanti_asiatic_school_surat/'>
            <img style={logoStyle}  src={instaLogo} alt="instagram"/>
            </a>
          </div>
          {/* <a href="#none">info@surat.shantiasiatic.com</a>
            <a href="#none">+91 75674 00000</a> */}
        </div>
        <div className='topbar-one__right'>
          {/* <a href="#none">Login</a>
            <a href="#none">Register</a> */}
          {/* <a href="#none">Admissions</a> */}
          <a href='/contact'>Contact Us</a>
          <a href='/careersatsas'>Career at S.A.S.</a>
          {/* <NewsLetter/> */}
        </div>
      </div>
    </div>    
  )
}

export default Topbar
