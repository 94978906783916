import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import PhotoGallery from "../components/PhotoGallery";
import Footer from "../components/Footer";
import Topbar from '../components/Topbar';

const PhotoPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Photo gallery">
            <Topbar />
            <NavOne />
            <PageHeader title="Photo gallery" />
            <PhotoGallery />
            <Footer />
        </Layout>
    );
};

export default PhotoPage;