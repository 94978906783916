import React, { Component } from 'react'
import { Link } from 'gatsby'
import logo from '../assets/images/IMG-20221121-WA0004.jpg'
import http from '../_api/http'
import AcademicPlannerPdf from '../assets/pdf/Academic-Planner.pdf'
import Topbar from "../components/Topbar";
import { Button, Modal } from 'react-bootstrap'

class NavOne extends Component {
  constructor() {
    super()
    this.state = {
      sticky: false,
      pdfsource: '',
      PUBLIC_URL: process.env.REACT_APP_APIURL,
      show: false,
      pdfList: [],
      aboutPdfList: [],
      academicPdfList: [],
      sasPdfList: []
    }
    this.handleClose = () => {
      this.setState({ show: false })
    }
    this.handleShow = () => {
      this.setState({ show: true })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    //Mobile Menu
    this.mobileMenu()

    //Search Toggle
    this.serachButton()

    http
      .get('api/pdf/ApiPdfList?pdf_for_type=6&pdf_selct_for=Acandemic Planner')
      .then(res => {
        if (res.data.status === 200) {
          if (res.data.pdf.length > 0) {
            this.state.pdfsource =
              this.state.PUBLIC_URL + 'files/' + res.data.pdf[0].name
          }
        }
      })

    http
      .get(
        'api/pdf/ApiPdfList?pdf_for_type=1'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          this.setState({ pdfList: res.data.pdf });
          // setLoading(false);
        }
      })

    http
      .get(
        'api/pdf/ApiPdfList?pdf_for_type=18'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          this.setState({ aboutPdfList: res.data.pdf });
          // setLoading(false);
        }
      })

    http
      .get(
        'api/pdf/ApiPdfList?pdf_for_type=19'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          this.setState({ academicPdfList: res.data.pdf });
          // setLoading(false);
        }
      })
    http
      .get(
        'api/pdf/ApiPdfList?pdf_for_type=20'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          this.setState({ sasPdfList: res.data.pdf });
          // setLoading(false);
        }
      })

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true
      })
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false
      })
    }
  }

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector('.menu-toggler')
    let mainNav = document.querySelector('.main-navigation')

    mainNavToggler.addEventListener('click', function () {
      mainNav.style.display =
        mainNav.style.display !== 'block' ? 'block' : 'none'
    })
  }

  serachButton = () => {
    let searchToggle = document.querySelector('.search-toggle')
    let searchPopup = document.querySelector('.search-popup')
    let searchClose = document.querySelector('.cancel')
    let searchOverlay = document.querySelector('.search-overlay')

    // searchToggle.addEventListener('click', function () {
    //   searchPopup.classList.add('active')
    // })

    searchClose.addEventListener('click', function () {
      searchPopup.classList.remove('active')
    })

    searchOverlay.addEventListener('click', function () {
      searchPopup.classList.remove('active')
    })
  }
  render() {
    return (
      <header className='site-header site-header__header-one '>
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${this.state.sticky ? 'stricked-menu stricky-fixed' : ''
            }`}
        >
          <div className='container clearfix' style={{padding:"0px"}}>
            <div className='logo-box clearfix'>
              <Link to='/' className='navbar-brand'>
                <img
                  src={logo}
                  className='main-logo'
                  width='145'
                  alt='Awesome alter text'
                />
              </Link>
              <div className='header__social'>
                {/* <a href="https://twitter.com/suratsas">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.facebook.com/shantiasiaticsurat/">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="https://www.youtube.com/channel/UChJ3gkcAe2V8fwM2xjV4yYg">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.instagram.com/shanti_asiatic_school_surat/">
                  <i className="fab fa-instagram"></i>
                </a> */}
              </div>
              <button className='menu-toggler'>
                <span className='kipso-icon-menu'></span>
              </button>
            </div>
            <div className='main-navigation'>
              <ul className=' navigation-box'>
                <li className='current'>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/'>About</Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/aboutori'>About </Link>
                    </li>
                    <li>
                      <Link to='/aboutchairman'>Chairman's Message </Link>
                    </li>
                    {/*  <li style={{display:"none"}}>
                      <Link to='/aboutdirector'>Director's Desk </Link>
              </li>*/}
                    <li>
                      <Link to='/aboutprincipal'>Principal's Desk </Link>
                    </li>
                    <li>
                      <Link to='/'>Team SAS</Link>
                      <ul className='sub-menu'>
                        {/* <li>
                          <Link to='/teamsasedu'>Educators</Link>
                        </li>
                        <li>
                          <Link to='/teamsasadmin'>Admin Team</Link>
                        </li> */}
                        {this.state.sasPdfList.map((item, i) => (
                          <li key={i}>
                            <a
                              href={this.state.PUBLIC_URL + 'files/' + item.name}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {item.pdf_select_for}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to='#' onClick={this.handleShow}>Mandatory Public Disclosure </Link>
                    </li>
                    {this.state.aboutPdfList.map((item, i) => (
                      <li key={i} >
                        <a
                          href={this.state.PUBLIC_URL + 'files/' + item.name}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {item.pdf_select_for}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li>
                  <Link to='/'>Academics</Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/preprimary'>Pre Primary</Link>
                    </li>
                    <li>
                      <Link to='/primary'>Primary</Link>
                    </li>
                    <li>
                      <Link to='/secondary'>Secondary</Link>
                    </li>
                    <li>
                      <Link to='/snrsecondary'>Senior Secondary</Link>
                    </li>
                    {this.state.academicPdfList.map((item, i) => (
                      <li key={i} >
                        <a
                          href={this.state.PUBLIC_URL + 'files/' + item.name}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {item.pdf_select_for}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li>
                  <Link to='/'>Student's Corner</Link>
                  <ul className='sub-menu'>
                    <li>
                      <a
                        href={this.state.pdfsource}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Academic Planner
                      </a>
                      {/* <Link to="{AcademicPlannerPdf}">Academic Planner </Link> */}
                    </li>
                    <li>
                      <Link to='/cocurricular1'>Co-curricular Activities </Link>
                    </li>
                    <li>
                      <Link to='/sport'>Sports </Link>
                    </li>
                    <li>
                      <Link to='/amd'>Art, Music and Dance </Link>
                    </li>
                    <li>
                      <Link to='/'>Major Events</Link>
                      <ul className='sub-menu'>
                        <li>
                          <Link to='/prayas'>Prayas</Link>
                        </li>
                        <li>
                          <Link to='/prabhat'>Prabhat</Link>
                        </li>
                        <li>
                          <Link to='/samarpan'>Samarpan</Link>
                        </li>
                        <li>
                          <Link to='/aashayein'>Aashayein</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to='/competitions'>Competitions</Link>
                    </li>
                    <li>
                      <Link to='/celebrations'>Celebrations</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/'>Parent's Corner</Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/canteenMenu'>Canteen Menu</Link>
                    </li>
                    <li>
                      <Link to='/circular'>Circular</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/'>Infrastructure</Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/campusinfra'>Campus Infrastructure</Link>
                    </li>
                    <li>
                      <Link to='/acandmyinfra'>Academic Infrastructure</Link>
                    </li>
                    <li>
                      <Link to='/sportinfra'> Sports Facilities</Link>
                    </li>
                    <li>
                      <Link to='/cocurricular'> Co-curricular Arrangements</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to='/'>Gallery</Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/media'>Media Coverage</Link>
                    </li>
                    <li>
                      <Link to='/photo'>Photo Gallery</Link>
                    </li>
                    <li>
                      <Link to='/youtubevideo'>Video Gallery</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/admission'>
                    <button className="thm-btn banner-one__btn btn admissionBtn p-2">Admission</button></Link>
                </li>
              </ul>
            </div>

          </div>
        </nav>
        <div className='site-header__decor'>
          <div className='site-header__decor-row'>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-1'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-2'></div>
            </div>
            <div className='site-header__decor-single'>
              <div className='site-header__decor-inner-3'></div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Mandatory Public Disclosure</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "overflow": "auto", "height": "400px" }}>
            <ul className='list-group'>
              {this.state.pdfList.map((item, i) => (
                <li key={i} className='list-group-item pdf-list'>
                  <a
                    href={this.state.PUBLIC_URL + 'files/' + item.name}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <h3 className='course-category-one__title model'>
                      {item.pdf_select_for}
                    </h3>
                  </a>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
    )
  }
}

export default NavOne
